import { useRouter } from 'next/router'
import React from 'react'

import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button'
import { PropsWithClassName } from '@Components/helper'

import { Routes } from '../../Routes'
import {
  Root,
  Styled3rdDDLink,
  StyledButton,
  StyledDDContainer,
  StyledDDLink,
  StyledDropDown,
  StyledHirachieWrapper,
  StyledLink,
} from './NavigationMain.styles'

interface Props extends PropsWithClassName {}

type NavigationLinksTypes = {
  href: string
  label: string
  isButton: boolean
  subNavs?: {
    href: string
    label: string
    isDisabled: boolean
    removeInWeb?: boolean
    subNavs?: {
      href: string
      label: string
      isDisabled: boolean
      removeInWeb?: boolean
    }[]
  }[]
}[]
export const NavigationLinks: NavigationLinksTypes = [
  {
    href: Routes.services,
    label: 'Services',
    isButton: false,
    subNavs: [
      {
        href: Routes.services,
        label: 'Übersicht',
        isDisabled: false,
      },
      {
        href: Routes.services_produktentwicklung,
        label: 'Digitale Produktentwicklung',
        isDisabled: false,
      },
      {
        href: Routes.services_itDienstleisterSoftwareentwicklung,
        label: 'Softwareentwicklung',
        isDisabled: false,
        subNavs: [
          {
            href: Routes.services_symfony_migration,
            label: 'Symfony Migration',
            isDisabled: false,
          },
          {
            href: Routes.services_symfony_upgrade,
            label: 'Symfony Upgrade',
            isDisabled: false,
          },
          {
            href: Routes.services_frontend_react,
            label: 'ReactJS Frontend',
            isDisabled: false,
          },
        ],
      },
    ],
  },
  {
    href: Routes.qossmic,
    label: 'QOSSMIC',
    isButton: false,
    subNavs: [
      {
        href: Routes.qossmic,
        label: 'Übersicht',
        isDisabled: false,
      },
      {
        href: Routes.qossmic_women_in_tech,
        label: 'Wo:men in Tech',
        isDisabled: false,
      },
    ],
  },
  { href: Routes.jobs, label: 'Jobs', isButton: false },
  {
    href: Routes.academy,
    label: 'Academy',
    isButton: false,
    subNavs: [
      {
        href: Routes.academy,
        label: 'Übersicht',
        isDisabled: false,
      },
      {
        href: Routes.services_symfony_trainings,
        label: 'Symfony Trainings',
        isDisabled: false,
      },
      {
        href: Routes.services_react_trainings,
        label: 'ReactJS Trainings',
        isDisabled: false,
      },
      {
        href: Routes.services_sulu_trainings,
        label: 'Sulu Trainings',
        isDisabled: false,
      },
    ],
  },
  { href: Routes.community, label: 'Community', isButton: false },
  {
    href: Routes.contact,
    label: 'Kontakt',
    isButton: true,
  },
]
const NavigationMain = (props: Props): React.ReactElement => {
  const router = useRouter() || {
    locale: '',
    push: () => {
      console.log('joa')
    },
  }
  const pathname = router != null ? router.pathname : ''
  return (
    <Root className={props.className} data-testid="NavigationMain-root">
      {NavigationLinks.map((link) => {
        if (link.subNavs) {
          const showDivider = link.subNavs.some((subNav) => subNav.subNavs)
          return (
            <StyledDDContainer key={link.label}>
              <StyledLink
                href={link.href}
                passHref
                active={pathname === link.href}
                data-testid={`navigation-main-${link.label.toLowerCase()}`}
              >
                {link.label}
              </StyledLink>
              {link.subNavs && (
                <StyledDropDown>
                  {link.subNavs.map((subNav) => {
                    if (subNav.removeInWeb) return
                    return (
                      <StyledHirachieWrapper
                        key={subNav.label}
                        showDivider={showDivider}
                      >
                        <StyledDDLink
                          disabled={subNav.isDisabled}
                          key={subNav.label}
                          href={subNav.href}
                        >
                          {subNav.label}
                        </StyledDDLink>
                        <>
                          {subNav.subNavs &&
                            subNav.subNavs.map((subSubNav) => {
                              return (
                                <Styled3rdDDLink
                                  key={subSubNav.label}
                                  href={subSubNav.href}
                                  disabled={subSubNav.isDisabled}
                                >
                                  {subSubNav.label}
                                </Styled3rdDDLink>
                              )
                            })}
                        </>
                      </StyledHirachieWrapper>
                    )
                  })}
                </StyledDropDown>
              )}
            </StyledDDContainer>
          )
        }
        if (link.isButton) {
          return (
            <StyledButton
              key={link.label}
              modifier={ButtonModifier.primary}
              type={ButtonType.button}
              href={link.href}
              dataTestId={`navigation-main-${link.label.toLowerCase()}`}
            >
              {link.label}
            </StyledButton>
          )
        } else {
          return (
            <StyledLink
              key={link.label}
              href={link.href}
              passHref
              active={pathname === link.href}
              data-testid={`navigation-main-${link.label.toLowerCase()}`}
            >
              {link.label}
            </StyledLink>
          )
        }
      })}
    </Root>
  )
}

export { NavigationMain }
